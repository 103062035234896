.scroll-container {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    animation: scroll 2s linear infinite;
  }
  
  .scroll-wrapper {
    display: flex;
    align-items: center;
  }
  
  .scroll-item {
    display: inline-block;
    margin: 0 10px;
    flex-shrink: 0;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-10%);
    }
  }
  